<template>
  <div>
    <div class="mb-5">Showing {{ showing }} of {{ refined.length }} {{ pluralize('result', refined.length) }}</div>

    <teacher-card
      v-for="user in page"
      :key="user.id"
      :user="user"
      @setUser="setUser"
    />

    <div>
      <v-btn 
        v-if="showing > 5"
        @click="
          prevPage()
          scrollUp()
        "
        class="rounded mr-1"
        depressed
        x-small
        fab
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn
        v-for="(n, i) in Math.ceil(refined.length/5)"
        :key="i"
        :color="activePage == n ? 'accent' : 'default'"
        @click="
          $store.commit('apply/setActivePage', n)
          scrollUp()
        "
        class="rounded mr-1"
        depressed
        x-small
        fab
      >
        <span class="caption">{{ n }}</span>
      </v-btn>
      <v-btn 
        v-if="showing < refined.length" 
        @click="
          nextPage()
          scrollUp()
        "
        class="rounded"
        depressed
        x-small
        fab
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>

    <!-- INVITE DIALOG -->
    <invite-dialog :user="user"></invite-dialog>
  </div>
</template>

<script>

import _pick from 'lodash/pick'
import firebase from 'firebase'
import _slice from 'lodash/slice'
import pluralize from 'pluralize'
import _orderBy from 'lodash/orderBy'
import TeacherCard from './TeacherCard'
const geofire = require('geofire-common')
import { mapState, mapActions } from 'vuex'
import _intersection from 'lodash/intersection'
import InviteDialog from '@/views/job/InviteDialog'

export default {
  props: {
    users: Array,
    order: String,
  },

  data() {
    return {
      pluralize,
      user: {}
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    InviteDialog,
    TeacherCard
  },

  /* -------------------------------------------------------------------------- */
  /*                                  COMPUTED                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      search: state => state.search.data,
      status: state => state.search.status,
      favorites: state => state.user.favorites,
      applicants: state => state.apply.applicants,
      hiredApplicants: state => state.apply.hiredApplicants,
      hits: state => state.search.hits,
      activePage: state => state.apply.status.activePage,
      school: state => state.schools.school,
      invitedJobs: (state) => state.invite.invitedJobs,
    }),

    isLoggedIn: function () {
      return firebase.auth().currentUser
    },

    refined: function () {
      let refined = this.users.filter(user => {
        return this.$store.getters['profile/userProfile'](user.userid)
               && this.$store.getters['profile/userProfile'](user.userid).skillset 
               && this.$store.getters['profile/userProfile'](user.userid).skillset.length
               && this.$store.getters['profile/userProfile'](user.userid).teachingLvl
              //  && this.$store.getters['profile/userProfile'](user.userid).aboutMe
      })
      
      // REFINE BY NAME
      if (this.search.teacher) {
        refined = refined.filter(teacher => {
          let valid = (teacher.firstName && teacher.firstName.toLowerCase().includes(this.search.teacher.toLowerCase())) ||
                      (teacher.lastName && teacher.lastName.toLowerCase().includes(this.search.teacher.toLowerCase())) ||
                      (teacher.fullName && teacher.fullName.toLowerCase().includes(this.search.teacher.toLowerCase()))

          if (valid) {
            teacher.valids = 1
          }

          return valid
        })
      }

      // REFINE BY EXPERIENCE
      if (this.search.experience) {
        refined = refined.filter(teacher => {
          let profile = this.$store.getters['profile/userProfile'](teacher.userid)
          if (profile) return profile.teachingLvl == this.search.experience
          else return false
        })
      }

      // IF VIDEO PROVIDED
      if (this.search.options.includes('Provided video')) {
        refined = refined.filter(teacher => {
          let profile = this.$store.getters['profile/userProfile'](teacher.userid)
          return profile.videoPresentation
        })
      }
      
      // IF CV ATTACHED
      if (this.search.options.includes('CV attached')) {
        refined = refined.filter(teacher => {
          let profile = this.$store.getters['profile/userProfile'](teacher.userid)
          return profile.resume
        })
      }
      
      // IF FAVOURITE
      if (this.search.options.includes('Favourite')) {
        refined = refined.filter(teacher => {
          return !!this.favorites.find(f => f.talentId == teacher.userid)
        })
      }
      
      // IF REFEREES
      if (this.search.options.includes('Provided referees')) {
        refined = refined.filter(teacher => {
          return !!this.$store.getters['profile/userReferees'](teacher.id).length
        })
      }
      
      // PRIOR EXPERIENCE AT MY SCHOOL
      if (this.search.options.includes('Prior experience at my school')) {
        refined = refined.filter(teacher => {
          return !!this.hiredApplicants.find(a => a.userid == teacher.userid)
        })
      }

      // POSITION
      if (this.search.position && this.search.position.length) {
        refined = refined.filter(teacher => {
          let profile = this.$store.getters['profile/userProfile'](teacher.userid)
          if (profile) return _intersection(profile.position, this.search.position).length
          else return false
        })
      }

      // SKILLSETS
      if (this.search.skillsets && this.search.skillsets.length) {
        refined = refined.filter(teacher => {
          let profile = this.$store.getters['profile/userProfile'](teacher.userid)
          if (profile) return _intersection(profile.skillset, this.search.skillsets).length || (this.search.options.includes('Include teachers with skills supervising relevant subjects') && _intersection(profile.willingSkills, this.search.skillsets).length)
          else return false
        })
      }

      // CHECK DISTANCE
      if ((Object.keys(this.school).length && this.school.address) || this.search.address) {
        const center = this.search.address  ? [this.search.address.latitude, this.search.address.longitude] : [this.school.address.latitude, this.school.address.longitude]
        
        refined = refined.filter(teacher => {
          if (teacher.address) {
            const distanceInKm = geofire.distanceBetween([teacher.address.latitude, teacher.address.longitude], center)
            return distanceInKm <= this.search.distance
          }
        })
      }

      // HITS
      if (this.hits.length || this.status.searched) {
        refined = refined.filter(teacher => {
          return !!this.hits.find(h => h.userID == teacher.id)
        })
      }

      return _orderBy(refined, this.order, this.order == 'createdAt' ? 'desc' : 'asc')
    },

    /*------------------------------------------------------------------------------
     * PAGINATION
     *----------------------------------------------------------------------------*/
    page: function () {
      let start = (this.activePage * 5) - 5
      let end = start + 5
      return _slice(this.refined, start, end)
    },

    pages: function () {
      return this.refined.length / 5
    },

    showing: function () {
      return ((this.activePage * 5) - 5) + this.page.length
    },

    showNext: function () {
      let diff = this.refined.length - (this.showing + 5)
      if (diff >= 5) {
        return this.showing + 5
      }
      else {
        return this.refined.length
      }
    }

    /*------------------------------------------------------------------------------
     * END PAGINATION
     *----------------------------------------------------------------------------*/
  },

  /*------------------------------------------------------------------------------
   * USERS
   *----------------------------------------------------------------------------*/
  watch: {
    'users': function () {
      this.users.forEach(user => {
        this.$store.dispatch('profile/getProfileById', _pick(user, ['id', 'userid']))
      })
    },

    'refined': function () {
      this.$store.commit('apply/setActivePage', 1)
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    nextPage() {
      let active = this.activePage
      active += 1
      this.$store.commit('apply/setActivePage', active)
    },

    prevPage() {
      let active = this.activePage
      active -= 1
      this.$store.commit('apply/setActivePage', active)
    },

    scrollUp() {
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
    },

    ...mapActions('invite',[
      'getOpenJob',
      'inviteTalent',
      'checkInvitedTalent'
    ]),

    setUser(user) {
      this.user = user
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (this.users && this.users.length) {
      this.users.forEach(user => {
        this.$store.dispatch('profile/getProfileById', _pick(user, ['id', 'userid']))
      })
    }
  }
}
</script>