<template>
  <div>
    <v-card outlined>
      <v-card-text class="pa-7">
        <h5 class="text-h5 font-weight-black primary--text">Refine Search</h5>

        <v-divider class="my-5"></v-divider>

        <div class="body-lg heading-font font-weight-black primary--text mb-3">Key Word Search</div>

        <div class="mb-5">
          <v-text-field
            v-model="searchTerm"
            @keydown.enter="searchTeachers(searchTerm)"
            @click:clear="$store.commit('search/clearHits')"
            placeholder="Other search terms"
            append-icon="mdi-magnify"
            hide-details
            class="mb-3"
            clearable
            outlined
            dense
          ></v-text-field>

          <v-btn
            @click="searchTeachers(searchTerm)"
            :loading="status.searching"
            color="accent gradient" 
            block
          >Find</v-btn>
        </div>

        <v-divider class="my-5"></v-divider>

        <div class="mb-3">
          <span class="body-lg heading-font font-weight-black primary--text">Distance</span>
        </div>

        <v-radio-group v-model="data.distance" hide-details>
          <v-radio
            v-for="(option, i) in [
              { text: 'Within 15km', value: 15 },
              { text: 'Within 50km', value: 50 },
              { text: 'Within 100km', value: 100 },
              { text: '100km +', value: 50000 },
            ]"
            :key="`distance-${i}`"
            :label="option.text" 
            :value="option.value"
          ></v-radio>
        </v-radio-group>

        <v-divider class="my-5"></v-divider>

        <div class="mb-3 d-flex align-center">
          <span class="body-lg heading-font font-weight-black primary--text">Years Experience</span>
          <v-btn 
            v-show="data.experience"
            @click="$store.commit('search/clearExperience')" 
            absolute
            right
            x-small
          >
            Clear 
          </v-btn>
        </div>

        <v-radio-group v-model="data.experience" hide-details>
          <v-radio
            v-for="(option, i) in [
              'Graduate',
              '2-4 years',
              '4-10 years',
              '10+ years',
            ]"
            :key="`experience-${i}`"
            :label="option"
            :value="option"
          ></v-radio>
        </v-radio-group>

        <template>
          
        </template>
        <v-divider class="my-5"></v-divider>
 
        <div class="body-lg heading-font font-weight-black primary--text mb-3">Other Details</div>

        <v-checkbox
          v-model="data.options"
          v-for="(option, i) in [
            'Prior experience at my school',
            'Favourite',
            'Provided referees',
            'Provided video',
            'CV attached',
            'Include teachers with skills supervising relevant subjects',
          ]"
          :key="`option-${i}`"
          :label="option"
          :value="option"
          hide-details
          name="options"
          class="ma-0"
        ></v-checkbox>

        <template v-if="user">
          <v-divider class="my-5"></v-divider>

          <v-btn
            @click="
              saveDialog = true
              $scrollTop()
            " 
            :loading="status.saving"
            color="accent gradient" 
            block
          >
            Save Search
          </v-btn>
          
          <v-btn
            @click="
              $store.commit('search/resetData')
              $emit('searched')
            " 
            class="mt-2"
            depressed
            block
          >
            Clear All
          </v-btn>

          <div class="mt-3 mb-1">Save and retrieve favourite searches</div>
          
          <div>
            <a href="#" @click.prevent="showTemplates = !showTemplates">
              Saved ({{ templates.length }})
            </a>
          </div>

          <v-card class="mt-2" v-show="showTemplates" v-if="templates.length" outlined>
            <v-list-item-group dense>
              <v-list-item
                v-for="(template, i) in templates"
                :key="template.id"
                @click="
                  $store.commit('search/setData', template)
                  $scrollTop()
                "
                :style="{borderBottom : (templates.length == (i + 1) ? 'none' : '1px solid #e0e0e0')}"
                dense
              >
                <v-list-item-title>
                  {{ template.name }}
                </v-list-item-title>
                <v-list-item-action>
                  <v-btn 
                  @click.stop="confirmDeleteAction(template)" 
                  small icon
                >
                    <v-icon small>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-card>
        </template>

      </v-card-text>
    </v-card>

    <!-- SAVE SEARCH TEMPLATE -->
    <v-dialog
      v-model="saveDialog"
      max-width="450"
    >
      <v-card>
        <v-btn @click="closeDialog()" absolute right top icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="font-weight-bold primary--text mb-3 heading-font">
          Name This Template
        </v-card-title>
        <v-card-text>
          <v-form ref="saveForm">
            <v-text-field
              v-model="data.name"
              outlined
            ></v-text-field>
          </v-form>

          <div>
            <v-btn
              @click="saveSearchTemplate()"
              :loading="status.saving"
              class="mr-2" 
              color="accent gradient"
            >
              Save
            </v-btn>
            <v-btn
              @click="closeDialog()" 
              :disabled="status.saving"
              text
            >
              Cancel
            </v-btn>
          </div>
        </v-card-text>
      </v-card> 
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import firebase from 'firebase'

export default {
  data() {
    return {
      saveDialog: false,
      gettingSkills: false,
      showTemplates: false,
      searchInput: null,
      searchTerm: null,
      toDelete: null,
    }
  },

  /* -------------------------------------------------------------------------- */
  /*                                  COMPUTED                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      status: state => state.search.status,
      data: state => state.search.data,
      templates: state => state.search.templates,
      skillsets: state => state.skillSets.skillsArray,
      user: state => state.user.user,
    }),
  },

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions('search', [
      'saveSearch', 
      'getTemplates', 
      'deleteTemplate', 
      'searchTeachers', 
    ]),
    
    ...mapActions('skillSets', [
      'getSkillSets'
    ]),

    closeDialog() {
      this.saveDialog = false
    },

    saveSearchTemplate() {
      Promise.all([this.saveSearch()])
      .then(() => {
        this.closeDialog()
        this.$store.commit('search/clearDateName')
      })
    },

    confirmDeleteAction(template) {
      this.toDelete = template
      this.$store.commit('setDeleteMessage', `Are you sure you want to delete template "${template.name}"?`)
      this.$store.commit('confirmDeleteState', true)
    },

    deleteConfirmed() {
      if (this.toDelete) {
        this.$store.commit('deletingState', true)
        
        Promise.all([
          this.deleteTemplate(this.toDelete)
        ])
        .then(() => {
          this.toDelete = null
          this.$store.commit('confirmDeleteState', false)
          this.$store.commit('deletingState', false )
        })
      }
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  created() {
    this.getSkillSets()
    let user = firebase.auth().currentUser

    if (user) {
      if (!this.$store.state.skillSets.status.firstLoad) {
        Promise.all([this.getSkillSets()])
        .then(() => {
          this.getTemplates()
        })
      }
      else {
        this.getTemplates()
      }
    } 


    this.$root.$on('deleteConfirmed', () => {
      this.deleteConfirmed()
    })


    if (this.$route.query.search) {
      this.searchTerm = this.$route.query.search
      this.searchTeachers(this.searchTerm)
    }
  }
}
</script>
