<template>
  <v-dialog v-model="status.showDialog" max-width="550" persistent>
    <v-card>
      <v-card-text class="pt-5">
        <div 
          class="font-weight-bold heading-font primary--text body-lg">
            Select which jobs you’d like to invite this teacher to consider
          </div>

        <div v-if="openJobs.length" class="mb-5 mt-3">
          <v-checkbox
            v-model="selectedJobInvites"
            v-for="(job, index) in openJobs"
            :key="index"
            :label="isInvited(job) ? `${job.title} - Already Invited` : job.title"
            :disabled="isInvited(job)"
            :value="job.id"
            hide-details
            class="ma-0"
          ></v-checkbox>
        </div>
        
        <div v-if="!openJobs.length">No available Open Jobs</div>
        
        <div class="mt-3 d-flex gap-7">
          <v-btn
            @click="confirmed()"
            :disabled="!selectedJobInvites.length"
            :loading="status.inviting"
            color="accent"
            class="gradient"
          >
            Invite
          </v-btn>

          <v-btn 
            @click="cancel()" 
            :disabled="status.inviting"
            depressed
          >
            Cancel
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: ['user'],

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      selectedJobInvites: [],
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: (state) => state.invite.status,
      invitedJobs: (state) => state.invite.invitedJobs,
      inviteds: (state) => state.invite.inviteds,
    }),

    openJobs: function () {
      return this.$store.getters['jobs/activeJobs'](false)
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'activeJobs': function (jobs) {
      if (jobs.length) {
        jobs.forEach(job => {
          this.getInviteds(job)
        })
      }
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('invite',[
      'inviteTalent',
      'getInviteds'
    ]),
    
    ...mapActions('jobs',[
      'getCreatedJobs',
    ]),

    cancel() {
      this.$store.commit('invite/setUpDialog', false)
      this.selectedJobInvites = []
    },

    confirmed() {
      Promise.all([
        this.inviteTalent({ jobIds: this.selectedJobInvites, talentId : this.user.userid})
      ])
      .then(() => {
        this.cancel()
      })
    },

    isInvited(job) {
      return !!this.inviteds.find(j => j.jobId == job.id && j.talentId == this.user.userid)
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  created() {
    this.getCreatedJobs()
  }
}
</script>