<template>
  <div class="flex-grow-1">
    <div class="mb-5">
      <h2 class="text-center font-weight-black" :class="$vuetify.breakpoint.xs ? 'text-h4' : 'text-h3'">Find a Teacher</h2>
    </div>

    <div class="mb-5">
      <v-card color="primary" class="search-bar">
        <v-card-text class="d-flex gap-20 align-center" :class="{ 'flex-wrap' : $vuetify.breakpoint.xs }">
          <div class="flex-grow-1">
            <v-row align="center" dense>
              <v-col cols="12" md="6">
                <v-select
                  v-model="mainSearch.position"
                  :items="orderItems(positions, 'order', 'asc')"
                  item-text="name"
                  item-value="name"
                  label="Position"
                  color="white"
                  prepend-icon="mdi-account"
                  style="z-index: 5"
                  hide-details
                  solo-inverted
                  clearable
                  multiple
                  outlined
                  dense
                  dark
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="mainSearch.skills"
                  :search-input.sync="skillsInput"
                  @change="skillsInput = null"
                  label="Skill set"
                  :items="skillsets"
                  prepend-icon="mdi-license"
                  color="white"
                  deletable-chips
                  solo-inverted
                  small-chips
                  hide-details
                  outlined
                  multiple
                  chips
                  dense
                  dark
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6" style="position: relative">
                <div class="address-container">
                  <vue-google-autocomplete
                    id="school-address"
                    ref="toAddress"
                    classname="address-field"
                    v-on:placechanged="getAddress"
                    placeholder="Search by suburb / town / city"
                    :fields="['address_components', 'geometry']"
                    types="(regions)"
                    country="au"
                    :disabled="!!mainSearch.address"
                    style="height: 40px; border: none; outline: unset !important;"
                  ></vue-google-autocomplete>

                  <v-btn @click="clearAddress()" v-if="mainSearch.address" style="margin-top: 5px" icon absolute right small>
                    <v-icon small color="white">mdi-close</v-icon>
                  </v-btn>
                </div>

                <v-text-field
                  name="name"
                  id="id"
                  prepend-icon="mdi-map-marker"
                  solo-inverted
                  small-chips
                  hide-details
                  readonly
                  outlined
                  multiple
                  chips
                  dense
                  dark
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="mainSearch.dates"
                  transition="scale-transition"
                  min-width="auto"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-combobox
                      v-model="mainSearch.dates"
                      v-bind="attrs"
                      v-on="on"
                      label="What dates do you need? (optional)"
                      prepend-icon="mdi-calendar-month-outline"
                      color="white"
                      hide-details
                      solo-inverted
                      outlined
                      readonly
                      chips
                      dense
                      dark
                    ></v-combobox>
                  </template>
                  <v-date-picker
                    v-model="mainSearch.dates"
                    scrollable
                    no-title
                    range
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      @click="$refs.menu.save(mainSearch.dates)"
                      color="primary"
                      text
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
          
          <v-btn
            @click="search()"
            color="accent gradient"
            class="ma-0"
            :block="$vuetify.breakpoint.xs"
            rounded
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-card-text>
      </v-card> 
    </div>

    <div class="mb-5 d-flex align-center gap-20">
      <div>
        <v-sheet max-width="900" color="transparent">
          Enter position details to find available teachers that match your needs. Refine or expand 	your search using the filters and change the order of results using the sort button.
        </v-sheet>
      </div>
      <v-spacer></v-spacer>
      <div>
        <!-- FILTER TEACHERS -->
        <v-btn @click="drawer = !drawer" class="rounded mb-3 d-inline-block d-sm-none" icon outlined>
          <v-icon>mdi-filter-menu</v-icon>
        </v-btn>

        <!-- ORDER TEACHERS -->
        <v-menu
          :close-on-content-click="false"
          offset-y
          bottom
          left
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="rounded" icon outlined>
              <v-icon>mdi-sort</v-icon>
            </v-btn>  
          </template>

          <v-card width="150">
            <v-card-text>
              <v-radio-group 
                v-model="order"
                class="mt-0"
                hide-details
              >
                <v-radio label="Newest" value="createdAt"></v-radio>
                <v-radio label="Name" value="firstName"></v-radio>
              </v-radio-group>
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
      
    </div>

    <!-- Filters -->
    <div>
      <v-navigation-drawer 
        v-model="drawer"
        :style="{ zIndex: '19 !important' }"
        fixed
      >
        <TalentFilter @searched="search" />
      </v-navigation-drawer>

      <v-row>
        <v-col cols="3" class="d-none d-sm-flex">
          <div>
            <TalentFilter @searched="search" />
          </div>
        </v-col>

        <v-col cols="12" md="9" class="jobs-section"> 
          <preloader v-if="status.getting" />
          <div class="text-center" v-if="!status.getting && !talents.length">
              No records found.
          </div>
          <div v-if="!status.getting && talents.length">
            <TalentCard 
              :order="order"
              :users="talents"
            />
          </div>
        </v-col>

      </v-row>
    </div>
    <!-- End Filters -->
  </div>
</template>

<script>
import TalentCard from '@/components/dashboard/talent/TalentCard'
import TalentFilter from '@/components/dashboard/talent/TalentFilter'
import { mapState, mapActions } from 'vuex'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import orderItems from 'lodash/orderBy'

export default {
  metaInfo: {
    title: 'Teachers'
  },

  data() {
    return {
      orderItems,
      searchFilterValue: null,
      order: 'createdAt',
      skillsInput: null,
      searching: false,
      position: null,
      address: null,
      drawer: false,
      skills: null,
      dates: null,
      menu: null,
      displayRanges: [
        {
          name: 'Last 60 Days',
          value: 60
        },
        {
          name: 'Last 30 Days',
          value: 30
        },
        {
          name: 'Last 7 Days',
          value: 7
        },
        {
          name: 'All',
          value: 0
        },
      ],

      orderBy: [
        {
          name: 'Newest First',
          value: 'createdAt'
        },
        {
          name: 'Oldest',
          value: 'asc'
        }
      ]
      }
    },

  components: {
    TalentCard,
    TalentFilter,
    VueGoogleAutocomplete,  
  },

/* -------------------------------------------------------------------------- */
/*                                  COMPUTED                                  */
/* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      users: state => state.users.talents,
      status: state => state.users.status,
      searchStatus: state => state.search.status,
      mainSearch: state => state.search.mainSearch,
      data: state => state.search.data,
      positions: state => state.position.data,
      skillsets: state => state.skillSets.skillsArray,
      school: state => state.schools.school,
      user: state => state.user.user,
    }),

    talents: function () {
      return this.users
    },
  },

/* -------------------------------------------------------------------------- */
/*                                    WATCH                                   */
/* -------------------------------------------------------------------------- */
  watch: {
    'user' : function(val) {
      if(val) {
        if(val && val.role == 'jobseeker'){
          this.$router.push({ name: 'Jobs' }).catch(()=>{})
        }
      }
    }
  },
/* -------------------------------------------------------------------------- */
/*                                   METHODS                                  */
/* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions('users', [
      'getTalents',
      'searchedTalents'
    ]),
    
    ...mapActions('user', [
      'getFavorites',
    ]),

    ...mapActions('favorite', [
      'getTalentFavorites',
    ]),
    
    ...mapActions('apply', [
      'getApplicants',
      'getHiredApplicants'
    ]),
    
    ...mapActions('position', [
      'getPositions',
    ]),
    
    ...mapActions('skillsets', [
      'getSkillSets',
    ]),
    
    ...mapActions('schools', [
      'getUserSchool',
    ]),

    search() {
      let data = {
        position: this.mainSearch.position,
        skillsets: this.mainSearch.skills,
        dates: this.mainSearch.dates,
        address: this.mainSearch.address,
      }

      this.$store.commit('search/setMainSearch', data)
    },

    getAddress(address) {
      this.$store.commit('search/setAddress', address)
    },

    clearAddress() {
      this.$store.commit('search/clearAddress')

      this.$nextTick(() => {
        this.$refs.toAddress.$refs.autocomplete.value = ''
      })
    }

  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    // this.$store.commit('search/resetData')
    
    if (!this.status.firstLoad) {
      this.getTalents()
      this.getFavorites()
      this.getApplicants()
      this.getPositions()
      this.getUserSchool()
      this.getTalentFavorites()
      this.getHiredApplicants()
    } 
    
    if (this.user && this.user.role == 'jobseeker') {
      this.$router.push({ name: 'Jobs' }).catch(()=>{})
    }
  }
}
</script>

<style lang="scss">

.address-container {
  position: absolute; 
  right: 0; 
  z-index: 5; 
  width: 100%; 
  padding-right: 12px; 
  padding-left: 46px;

  #school-address {
    color: var(--v-primary-base);

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--v-primary-base);;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: var(--v-primary-base);;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: var(--v-primary-base);;
    }
  }
}

</style>