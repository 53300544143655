<template>
  <v-card
    class="mb-6"
    :style="{ cursor: 'default !important' }"
    outlined
    hover
  >
    <v-card-text class="pl-9 pr-9">
      <v-row>
        <v-col class="d-flex justify-center" cols="12" md="3">
          <div>
            <v-card outlined>
              <v-card-text class="pa-1">
                <UserPhoto
                  :blur="!$store.getters['pricing/withActiveSub']"
                  :id="user.userid"
                  photoSize="small"
                  v-if="user"
                  :size="135"
                  tile
                />
              </v-card-text>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" md="9">
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-space-between align-center mb-3" :class="{ 'flex-wrap' : $vuetify.breakpoint.xs }">
                <span class="text-h5 font-weight-bold text-capitalize d-block d-sm-inline">
                  <span
                    v-if="!$store.getters['pricing/withActiveSub']"
                    class="text-capitalize text-decoration-none primary--text"
                  >
                     {{ formatName(user.firstName) }}
                  </span>
                  <router-link
                    v-else
                    :to="isLoggedIn ? { name: 'TalentProfile', params: { id: user.userid } } : { name: 'Signup' }"
                    class="text-capitalize text-decoration-none underline-on-hover"
                  >
                    {{ user.firstName }} {{ user.lastName }}
                  </router-link>
                </span>
                <div class="d-flex gap-10 align-center">
                  <span v-if="$store.getters['profile/userProfile'](user.userid)" class="body-1">
                    {{ $store.getters['profile/userProfile'](user.userid).teachingLvl }}
                  </span>
                  
                  <FavouriteTeacher v-if="isLoggedIn" :user="user"/>
                </div>
              </div>
              <div v-if="(profile.skillset && profile.skillset.length) || user.address"> 
                <v-row dense>
                  <v-col v-if="profile.skillset && profile.skillset.length" cols="12" md="7" class="mb-5 mb-sm-0">
                    <div class="d-flex gap-7 flex-wrap">
                      <v-chip
                        v-for="(skill, skillIndex) in profile.skillset"
                        :key="skillIndex"
                        color="grey lighten-3"
                        small
                      >
                        {{ skill }}
                      </v-chip>
                    </div>
                  </v-col>
                  <v-col cols="12" md="5" class="text-lef text-sm-right " :offset-sm="profile.skillset && profile.skillset.length ? 0 : 7">
                    <template v-if="user.address">
                      <span class="dark--text d-inline-flex text-left text-sm-right align-center font-weight-medium text-capitalize">
                        <v-icon class="d-inline-block d-sm-none" left size="25">mdi-map-marker-outline</v-icon>
                        <span v-if="false">{{ [`${user.address.street_number || '' } ${user.address.route || ''}`, user.address.locality, user.address.administrative_area_level_1].join(', ') }}</span>
                        <span v-else>{{ [user.address.locality, user.address.administrative_area_level_1].join(', ') }}</span>
                        <v-icon class="d-none d-sm-inline-block" right size="22">mdi-map-marker-outline</v-icon>
                      </span>
                    </template>
                  </v-col>
                </v-row>
              </div>
              <v-divider class="my-5"></v-divider>
              <p class="job-description body-1 ls-0">
                {{ profile.aboutMe }}
              </p>
              <div v-if="isLoggedIn && $store.getters['pricing/withActiveSub']" class="mt-5">
                <router-link
                  :to="{ name: 'TalentProfile', params: { id: user.userid } }"
                  class="font-weight-bold primary--text heading-font text-decoration-none underline-on-hover"
                >
                  View More
                </router-link>
              </div>

              <div class="mt-5 d-flex align-center flex-wrap" :class="$vuetify.breakpoint.xs ? 'gap-5' : 'gap-20'">
                <div class="d-flex align-center">
                  <v-icon :color="$store.getters['profile/userReferees'](user.id).length ? 'accent' : 'grey'" :left="!$vuetify.breakpoint.xs">
                    {{ $store.getters['profile/userReferees'](user.id).length ? 'mdi-check' : 'mdi-close' }}
                  </v-icon>
                  <span>Referees</span>
                </div>
                
                <div class="d-flex align-center">
                  <v-icon :color="$store.getters['profile/userProfile'](user.userid).resume ? 'accent' : 'grey'" :left="!$vuetify.breakpoint.xs">
                    {{ $store.getters['profile/userProfile'](user.userid).resume ? 'mdi-check' : 'mdi-close' }}
                  </v-icon>
                  <span>CV attached</span>
                </div>
                
                <div class="d-flex align-center">
                  <v-icon :color="$store.getters['profile/userProfile'](user.userid).videoPresentation ? 'accent' : 'grey'" :left="!$vuetify.breakpoint.xs">
                    {{ $store.getters['profile/userProfile'](user.userid).videoPresentation ? 'mdi-check' : 'mdi-close' }}
                  </v-icon>
                  <span>Video</span>
                </div>
                
                <v-spacer></v-spacer>
                
                <v-btn
                  v-if="isLoggedIn && $store.getters['pricing/withActiveSub']"
                  @click="inviteJob(user)"
                  color="accent gradient"
                  :block="$vuetify.breakpoint.xs"
                  class="mt-2 mt-sm-n3"
                >
                  Invite To A Job
                </v-btn>
                
                <v-btn
                  v-if="!isLoggedIn"
                  href="https://www.teacherfinder.com.au/pricing/"
                  color="accent gradient"
                  min-height="60"
                >
                  <div class="text-center" style="line-height: 1.4">
                    Full Details Available <br> With Subscription
                  </div>
                </v-btn>
                
                <v-btn
                  v-else-if="!$store.getters['pricing/withActiveSub']"
                  :to="{ name: 'Subscription' }"
                  color="accent gradient"
                  min-height="60"
                >
                  <div class="text-center" style="line-height: 1.4">
                    Full Details Available <br> With Subscription
                  </div>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import firebase from 'firebase'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    user: {
      type: Object,
      required: true
    }
  },

  computed: {
    profile: function () {
      return this.$store.getters['profile/userProfile'](this.user.userid)
    },

    isLoggedIn: function () {
      return firebase.auth().currentUser
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('invite',[
      'getOpenJob',
      'inviteTalent',
      'checkInvitedTalent'
    ]),

    formatName(name) {
      if (this.$store.getters['pricing/withActiveSub']) {
        return name
      }
      else {
        return `${name.substr(0,3)}...`
      }
    },

    inviteJob(user) {
      this.$emit('setUser', user)
      this.getOpenJob()
      this.$store.commit('invite/setUpDialog', true)
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (this.user) {
      this.$store.dispatch('profile/getRefereeById', this.user.id)
      this.$store.dispatch('user/getFavorites')
    }
  }
}
</script>